#login {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: absolute;
    background-image: url("/src/assets/HUD/TitleScreen-export.png");
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
}

#login2 {

    background-image: url("/src/assets/HUD/TitleScreen-export.png");
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
}

body {
    padding: 0;
    margin: 0;
    overflow: hidden;
    cursor: url('/src/assets/HUD/feuille_cursor.png') 0, auto;
}

#title_login {
    font-size: 500%;
    font-family: 'VT323', monospace;
}

.input_login {
    font-size: xx-large;
    font-family: 'VT323', monospace;
    margin-bottom: 1rem;
    width: 100%;
}

.cloudLeft {
    bottom: -10%;
    left: -10%;
    width: 50%;
    height: 50%;
    position: absolute;
    background-size: contain;
    transform: scaleX(-1);
    min-width: 400px;
    background-position: bottom;
    background-image: url("/src/assets/HUD/clouds.png");
    pointer-events: none;
    background-repeat: no-repeat;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
    z-index: 10002;
}

.cloudRight {
    top: -10%;
    right: -10%;
    width: 50%;
    height: 50%;
    position: absolute;
    min-width: 400px;
    transform: scaleY(-1);
    background-image: url("/src/assets/HUD/clouds.png");
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
    z-index: 10002;

}

.inputs {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px, 20px, 20px, 20px;
    gap: 10px;
}

.cloudLeft-active {
    left: -150%;
}

.cloudRight-active {
    right: -150%;
}


.loginClass2{
    pointer-events: none;
    opacity: 70%;
    z-index: -3;
}

.loginClass-active {
    opacity: 0%;
}
.error {
    border: 2px solid red;
}

.error::placeholder {
    color: red;
}

.div_login {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.transform {
    -webkit-transition: all 6s ease;
    -moz-transition: all 6s ease;
    -o-transition: all 6s ease;
    -ms-transition: all 6s ease;
    transition: all 6s ease;
}

.transformSlow {
    -webkit-transition: all 2s ease;
    -moz-transition: all 2s ease;
    -o-transition: all 2s ease;
    -ms-transition: all 2s ease;
    transition: all 2s ease;
}

.connection-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

@media only screen and (max-width: 620px) {
    #title_login {
        font-size: 175%;
        font-family: 'VT323', monospace;
    }
    .input_login {
        width: 70%;
        font-size: x-large;
        font-family: 'VT323', monospace;
        margin-bottom: 1rem;
    }
}